<template>
  <div>
    <div class="container">
      <div class="card px-5">

        <h1 class="my-4">Samples</h1>

        <div v-for="(sample, index) in samples" style="margin-bottom: 2rem;">
          <MusicPlayer
            :filename="sample.filename"
            extension="wav"
            :name="sample.name"
            :bg-index="index"
            :download-name="sample.download_name"
            :download-file="sample.download_file"
          ></MusicPlayer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MusicPlayer from './music-player'

import store from '../store'

export default {
  components: { MusicPlayer },
  data() {
    return {
      extension: 'mp3',
    }
  },
  computed: {
    samples() {
      return store.getters.samples
    }
  },
  created() {
  }
}

</script>
